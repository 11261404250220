<main>
  <div class="dashboard__wrapper">
    <div class="dashboard__topbar">
      <span class="flex-grow-1"></span>
      <div class="top-action-right">
        @if (editDashboard) {
          <div class="roles-dropdown">
            <calimatic-dropdown [configs]="{
            options:userRoles,
            label:'Name',
            value:'Code',
            placeholder:'Select Role',
            selectable:true,
            }" [(value)]="dashboardWidgetRole" (valueChange)="onRoleChange()">
            </calimatic-dropdown>
          </div>
          <div class="add-widget-dropdown flex gap-1 flex-wrap">
            <p-button [disabled]="restoreLoader" (click)="widgetModalVisibility = true"
                      styleClass="clm-primary-btn btn-sm"
                      label="Add widgets"
                      icon="fa-solid fa-plus"></p-button>
            <p-dialog [(visible)]="widgetModalVisibility"
                      styleClass="a-w-panel-wrapper">
              <div class="add-widget-dd">
                <div class="widget-searchable">
                                    <span class="p-input-icon-left">
                                        <i class="fa-solid fa-magnifying-glass"></i>
                                        <input type="text" pInputText [(ngModel)]="widgetSearch"
                                               (ngModelChange)="setNonSelectedWidgets()"
                                               placeholder="Search widget"/>
                                    </span>
                </div>
                <div class="widget-items-main">
                  <div class="widget-item-left">
                    <ng-template [ngTemplateOutlet]="nonSelectedWidgetsTemplate"
                                 [ngTemplateOutletContext]="{name:'Counters - Current Month',id:sectionWidgetEnum.MonthlyCounters}"></ng-template>
                    <ng-template [ngTemplateOutlet]="nonSelectedWidgetsTemplate"
                                 [ngTemplateOutletContext]="{name:'Counters - Today, This Month & Last Month',id:sectionWidgetEnum.MonthlyTrioCounters}"></ng-template>
                  </div>
                  <div class="widget-item-right">
                    <ng-template [ngTemplateOutlet]="nonSelectedWidgetsTemplate"
                                 [ngTemplateOutletContext]="{name:(('Courses' | terminology:terminologyEnum.Course: terminologyWordType.PLURAL) + '/' + ('Classes' | terminology:terminologyEnum.Clas: terminologyWordType.PLURAL)+' Detail Charts'),id:sectionWidgetEnum.EnrollmentCharts}"></ng-template>

                    <ng-template [ngTemplateOutlet]="nonSelectedWidgetsTemplate"
                                 [ngTemplateOutletContext]="{name:'Revenue Detail Charts',id:sectionWidgetEnum.RevenueCharts}"></ng-template>

                    <ng-template [ngTemplateOutlet]="nonSelectedWidgetsTemplate"
                                 [ngTemplateOutletContext]="{name:'Student Detail Charts',id:sectionWidgetEnum.StudentCharts}"></ng-template>

                    <ng-template [ngTemplateOutlet]="nonSelectedWidgetsTemplate"
                                 [ngTemplateOutletContext]="{name:'Leads Detail Charts',id:sectionWidgetEnum.LeadsCharts}"></ng-template>

                    <ng-template [ngTemplateOutlet]="nonSelectedWidgetsTemplate"
                                 [ngTemplateOutletContext]="{name:'Widgets',id:sectionWidgetEnum.Widgets}"></ng-template>
                  </div>
                </div>
              </div>
            </p-dialog>
            <p-button [disabled]="restoreLoader" [loading]="restoreLoader" (click)="restoreToDefault()"
                      styleClass="clm-danger-btn btn-sm"
                      label="Restore to default"
                      icon="fa-solid fa-rotate-right fa-flip-horizontal"></p-button>
          </div>
          <button pButton (click)="onEditDashboard()" class="clm-secondary-btn danger btn-sm">
            <span>Close edit mode</span>
          </button>
        } @else {
          @if (loggedInUserRole == userRolesEnum.BusinessAdmin && !this.showLoader) {
            <calimatic-help-video text="Learn about dashboard customization"
                                  [link]="dashboardVideo"></calimatic-help-video>
            <p-button (click)="onEditDashboard()" styleClass="clm-primary-btn btn-sm cdr" label="Edit Dashboard"
                      icon="fa-solid fa-pen" class="hidden-1100"></p-button>
          }
        }

      </div>
    </div>

    <div class="dashboard__body">
      @if (showLoader) {
        <div class="flex-row flex gap-3">
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
        </div>
        <div class="flex-row flex gap-3">
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
        </div>
        <div class="flex-row flex gap-3">
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
          <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
        </div>
        <div class="flex-row flex gap-3">
          <calimatic-chart-skeleton class="w-full"></calimatic-chart-skeleton>
          <calimatic-chart-skeleton class="w-full"></calimatic-chart-skeleton>
          <calimatic-chart-skeleton class="w-full"></calimatic-chart-skeleton>
        </div>
        <div class="flex-row flex gap-3">
          <calimatic-chart-skeleton class="w-full"></calimatic-chart-skeleton>
          <calimatic-chart-skeleton class="w-full"></calimatic-chart-skeleton>
          <calimatic-chart-skeleton class="w-full"></calimatic-chart-skeleton>
        </div>
      } @else {
        <gridstack #gs [options]="gridStackOpts" (addedCB)="updateWidgets($event)" (changeCB)="updateWidgets($event)">
          @for (item of selectedWidgets; track item.id) {
            <gridstack-item
              #gsi
              [options]="{
              autoPosition: item.autoPosition,
              w:item.width,
              h:item.height,
              x:item.autoPosition ? null : item.xaxis,
              y:item.autoPosition ? null : item.yaxis,
              minW:item.minWidth,
              minH:item.minHeight,
              id:item.id}">

              @if (item.code == widgetEnums.EnrollmentsCount ||
              item.code == widgetEnums.DropOffCount ||
              item.code == widgetEnums.RevenueGenerated ||
              item.code == widgetEnums.Attendance ||
              item.code == widgetEnums.UsersCount ||
              item.code == widgetEnums.StudentsEngagement ||
              item.code == widgetEnums.FamiliesCount ||
              item.code == widgetEnums.StudentsCount ||
              item.code == widgetEnums.StaffCount ||
              item.code == widgetEnums.ContactsCount ||
              item.code == widgetEnums.LeadsCount ||
              item.code == widgetEnums.PendingPaymentsCount ||
              item.code == widgetEnums.AssignedStudents ||
              item.code == widgetEnums.AssignedCourses ||
              item.code == widgetEnums.HoursLogged ||
              item.code == widgetEnums.ClassesAssigned ||
              item.code == widgetEnums.CoursesEnrolled ||
              item.code == widgetEnums.StudentResults) {

                <calimatic-counter-widget [widget]="item" (onRemove)="removeWidget(item)"
                                          [edit]="editDashboard">
                </calimatic-counter-widget>
              }

              @if (item.code == widgetEnums.MonthlyTrioEnrollmentsCount ||
              item.code == widgetEnums.MonthlyTrioDropOffCount ||
              item.code == widgetEnums.MonthlyTrioRevenueGenerated ||
              item.code == widgetEnums.MonthlyTrioAttendance ||
              item.code == widgetEnums.MonthlyTrioUsersCount ||
              item.code == widgetEnums.MonthlyTrioStudentsEngagement ||
              item.code == widgetEnums.MonthlyTrioFamiliesCount ||
              item.code == widgetEnums.MonthlyTrioStudentsCount ||
              item.code == widgetEnums.MonthlyTrioStaffCount ||
              item.code == widgetEnums.MonthlyTrioContactsCount ||
              item.code == widgetEnums.MonthlyTrioLeadsCount ||
              item.code == widgetEnums.MonthlyTrioPendingPaymentsCount ||
              item.code == widgetEnums.MonthlyTrioAssignedStudents ||
              item.code == widgetEnums.MonthlyTrioAssignedCourses ||
              item.code == widgetEnums.MonthlyTrioHoursLogged ||
              item.code == widgetEnums.MonthlyTrioClassesAssigned) {
                <calimatic-counter-trio-widget [widget]="item" (onRemove)="removeWidget(item)"
                                               [edit]="editDashboard">
                </calimatic-counter-trio-widget>
              }

              @if (item.code == widgetEnums.TopFranchises) {
                <calimatic-top-franchises-revenue-widget [widget]="item" [edit]="editDashboard"
                                                         (onRemove)="removeWidget(item)"></calimatic-top-franchises-revenue-widget>
              }

              @if (item.code == widgetEnums.ListOfLeads) {
                <calimatic-leads-widget [widget]="item" [edit]="editDashboard"
                                        (onRemove)="removeWidget(item)"></calimatic-leads-widget>
              }

              @if (item.code == widgetEnums.EnrollmentPerformanceChart) {
                <calimatic-enrollment-performance [types]="types" [eventTypes]="eventTypes" [widget]="item"
                                                  [isFranchise]="userModal.IsFranchiseCompany"
                                                  [companyId]="userModal.CompanyId" (onRemove)="removeWidget(item)"
                                                  [edit]="editDashboard" [franchises]="franchises">
                </calimatic-enrollment-performance>
              }

              @if (item.code == widgetEnums.EnrollmentComparisonChart) {
                <calimatic-enrollment-comparison [types]="types" [eventTypes]="eventTypes" [widget]="item"
                                                 (onRemove)="removeWidget(item)"
                                                 [edit]="editDashboard">
                </calimatic-enrollment-comparison>
              }

              @if (item.code == widgetEnums.PartnersEnrollmentChart) {
                <calimatic-partner-enrollment [widget]="item" [isFranchise]="userModal.IsFranchiseCompany"
                                              [companyId]="userModal.CompanyId" [franchises]="franchises"
                                              (onRemove)="removeWidget(item)"
                                              [edit]="editDashboard"></calimatic-partner-enrollment>
              }
              @if (item.code == widgetEnums.ClassDropOffChart) {
                <calimatic-class-drop-off-widget [widget]="item"
                                                 [companyId]="userModal.CompanyId"
                                                 [isFranchise]="userModal.IsFranchiseCompany"
                                                 (onRemove)="removeWidget(item)"
                                                 [edit]="editDashboard"
                                                 [franchises]="franchises"
                                                 [types]="types"></calimatic-class-drop-off-widget>
              }

              @if (item.code == widgetEnums.EventDropOffChart) {
                <calimatic-event-drop-off-widget [widget]="item"
                                                 [companyId]="userModal.CompanyId"
                                                 [isFranchise]="userModal.IsFranchiseCompany"
                                                 (onRemove)="removeWidget(item)" [franchises]="franchises"
                                                 [edit]="editDashboard"
                                                 [types]="eventTypes"></calimatic-event-drop-off-widget>
              }

              @if (item.code == widgetEnums.CourseTypeCountChart) {
                <calimatic-course-type-widget [widget]="item" (onRemove)="removeWidget(item)"
                                              [edit]="editDashboard"></calimatic-course-type-widget>
              }

              @if (item.code == widgetEnums.ClassEnrollmentChart) {
                <calimatic-class-enrollment-count-widget [widget]="item" (onRemove)="removeWidget(item)"
                                                         [isFranchise]="userModal.IsFranchiseCompany"
                                                         [companyId]="userModal.CompanyId"
                                                         [edit]="editDashboard"
                                                         [franchises]="franchises"
                                                         [types]="types"></calimatic-class-enrollment-count-widget>
              }

              @if (item.code == widgetEnums.EventEnrollmentChart) {
                <calimatic-event-enrollment-count-widget [widget]="item" (onRemove)="removeWidget(item)"
                                                         [isFranchise]="userModal.IsFranchiseCompany"
                                                         [companyId]="userModal.CompanyId"
                                                         [edit]="editDashboard"
                                                         [franchises]="franchises"
                                                         [types]="eventTypes"></calimatic-event-enrollment-count-widget>
              }

              @if (item.code == widgetEnums.LeadsGenerationChart) {
                <calimatic-leads-generation-widget [widget]="item" [edit]="editDashboard"
                                                   (onRemove)="removeWidget(item)"
                                                   [isFranchise]="userModal.IsFranchiseCompany"
                                                   [companyId]="userModal.CompanyId"
                                                   [franchises]="franchises"></calimatic-leads-generation-widget>
              }

              @if (item.code == widgetEnums.StudentDemographicsChart) {
                <calimatic-student-demographics-widget [widget]="item" [edit]="editDashboard"
                                                       (onRemove)="removeWidget(item)"
                                                       [isFranchise]="userModal.IsFranchiseCompany"
                                                       [companyId]="userModal.CompanyId"
                                                       [franchises]="franchises"></calimatic-student-demographics-widget>
              }

              @if (item.code == widgetEnums.StudentAttendanceChart) {
                <calimatic-student-attendance-widget [widget]="item" [edit]="editDashboard"
                                                     (onRemove)="removeWidget(item)"
                                                     [isFranchise]="userModal.IsFranchiseCompany"
                                                     [companyId]="userModal.CompanyId"
                                                     [franchises]="franchises"></calimatic-student-attendance-widget>
              }

              @if (item.code == widgetEnums.Calendar) {
                <calimatic-dashboard-calendar-widget [widget]="item" [edit]="editDashboard"
                                                     (onRemove)="removeWidget(item)"></calimatic-dashboard-calendar-widget>
              }

              @if (item.code == widgetEnums.Map) {
                <calimatic-map-widget [companyId]="userModal.CompanyId" [edit]="editDashboard"
                                      (onRemove)="removeWidget(item)"></calimatic-map-widget>
              }

              @if (item.code == widgetEnums.QuickLinks) {
                <calimatic-quick-links-widget [menus]="menuStore.menus()" [edit]="editDashboard"
                                              (onRemove)="removeWidget(item)"></calimatic-quick-links-widget>
              }

              @if (item.code == widgetEnums.Announcements) {
                <calimatic-announcements-widget [widget]="item" [edit]="editDashboard"
                                                (onToggle)="onAnnouncementToggle($event,gsi)"
                                                (onRemove)="removeWidget(item)"></calimatic-announcements-widget>
              }

              @if (item.code == widgetEnums.ExternalLink1 || item.code == widgetEnums.ExternalLink2 || item.code == widgetEnums.ExternalLink3 || item.code == widgetEnums.ExternalLink4 || item.code == widgetEnums.ExternalLink5) {
                <calimatic-external-widget (close)="externalLinkModal = false"
                                           (open)="showExternalLinkModal($any($event))"
                                           (onRemove)="removeWidget(item)"
                                           [widget]="item"
                                           [edit]="editDashboard"
                                           [editOnHover]="loggedInUserRole == userRolesEnum.FranchiseAdmin"></calimatic-external-widget>
              }

              @if (item.code == widgetEnums.ClassEnrollmentRevenueChart) {
                <calimatic-class-revenue-widget [widget]="item" [companyId]="userModal.CompanyId"
                                                [isFranchise]="userModal.IsFranchiseCompany"
                                                (onRemove)="removeWidget(item)"
                                                [edit]="editDashboard"
                                                [franchises]="franchises"
                                                [types]="types"></calimatic-class-revenue-widget>
              }
            </gridstack-item>
          }
        </gridstack>
      }
    </div>
  </div>
</main>
<!-- <calimatic-student-points-widget></calimatic-student-points-widget> -->

<!-- <div class="dashboard-config">
    <button pButton type="button" class="" (click)="showSideBar = true">
        <i class="fa-light fa-gear"></i>
        <span>Dashboard Configurations</span>
    </button>
</div>
<p-sidebar #sidebarRef [(visible)]="showSideBar" position="right" styleClass="main-sidebar">
    <ng-template pTemplate="headless">
        <calimatic-dashboard-config (closeSideBar)="onCloseSideBar($event)" [dashboardConfigCardItems]="dashboardCardItems" class="h-full"></calimatic-dashboard-config>
    </ng-template>
</p-sidebar> -->

<ng-template #nonSelectedWidgetsTemplate let-id="id" let-name="name">
  <ul class="widget-items widget-items-counters">
    <li class="title">
      <h5 class="mb-0">{{ name }}</h5>
    </li>
    @for (item of (filterByCategory | memoized:nonSelectedWidgets:id); track item.id) {
      <li (click)="addWidget(item)">
        <div class="widget-item hover">
          <div class="widget-item-card">
            <div class="widget-icon">
              <i class="{{item.icon}}"></i>
            </div>
            <div>
              <h6 class="mb-0">{{ item.title }}</h6>
              <p class="mb-0">{{ item.description }}</p>
            </div>
          </div>
          <p-button (click)="addWidget(item)" class="add-to-dashboard-btn"
                    styleClass="tiny bg-white"
                    [outlined]="true" [rounded]="true" label="Add to Dashboard"></p-button>
        </div>
      </li>
    }
  </ul>
</ng-template>

<p-dialog [(visible)]="externalLinkModal" [closable]="true" [modal]="true" [styleClass]="'clm-sm-modal'"
          draggable="false"
          header="External link settings">
  <form (submit)="onExternalLinkFormSubmit()" [formGroup]="externalLinkForm" class="pt-3">
    <div class="flex md:flex-row flex-column md:align-items-center gap-1 mb-2">
      <label class="max-w-8rem w-full mb-1" for="name">Name</label>
      <div class="w-full">
        <input
          [ngClass]="{'ng-invalid ng-dirty':externalLinkFormControls.name.invalid && (externalLinkFormControls.name.dirty || !externalLinkFormControls.name.untouched)}"
          autocomplete="off"
          class="w-full"
          formControlName="name" id="name" pInputText/>
        <span class="form-validation-helper block h-1rem">
          @if (externalLinkFormControls.name.invalid && (externalLinkFormControls.name.dirty || !externalLinkFormControls.name.untouched)) {
            <span class="form-invalid block">Name is required</span>
          }
        </span>
      </div>
    </div>
    <div class="flex md:flex-row flex-column md:align-items-center gap-1 mb-2">
      <label class="max-w-8rem w-full mb-1" for="link">Link</label>
      <div class="w-full">
        <p-inputGroup>
          <p-inputGroupAddon>https://</p-inputGroupAddon>
          <input
            [ngClass]="{'ng-invalid ng-dirty':externalLinkFormControls.link.invalid && (externalLinkFormControls.link.dirty || !externalLinkFormControls.link.untouched)}"
            autocomplete="off" class="flex-auto" formControlName="link" id="link" pInputText/>
        </p-inputGroup>
        <span class="form-validation-helper block h-1rem">
          @if (externalLinkFormControls.link.invalid && (externalLinkFormControls.link.dirty || !externalLinkFormControls.link.untouched)) {
            <span class="form-invalid block">Link is required</span>
          }
        </span>
      </div>
    </div>
    <div>
      <div class="flex md:flex-row flex-column md:align-items-center gap-1 mb-2">
        <label class="max-w-8rem w-full" for="link">Icon</label>
        <div class="w-full">
          <input formControlName="icon" type="hidden">
          <span class="ml-1 fa {{ externalLinkFormControls.icon.value }}"></span>
        </div>
      </div>
      <p-divider></p-divider>
      <calimatic-icons (selectedIcon)="selectedExternalLinkIcon($event)"></calimatic-icons>
      <div class="flex justify-content-end gap-2 pt-2">
        <p-button (click)="externalLinkModal = false" [disabled]="showExternalLinkLoader" label="Cancel"
                  styleClass="clm-secondary-btn"/>
        <p-button [disabled]="externalLinkForm.invalid || showExternalLinkLoader" [loading]="showExternalLinkLoader"
                  label="Save" styleClass="clm-primary-btn" type="submit"/>
      </div>
    </div>
  </form>
</p-dialog>
