@if (loading) {
  <calimatic-chart-skeleton></calimatic-chart-skeleton>
} @else {
  <div class="clm-widget-item card">
    <div class="card-action-wrapper">
      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'Remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      }
    </div>
    <div class="card-header">
      <h5>Location Map</h5>
    </div>
    <div class="card-body">
      <calimatic-map [popupType]="mapPopupTypeEnum.Dashboard" [askLocation]="true" [companyId]="companyId()" [data]="data"></calimatic-map>
    </div>
  </div>

}
