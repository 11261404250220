import {ApiHandlerService} from "../base/api-handler.service";
import {API_ENDPOINTS, AppConstants, AppTokens} from "../../../shared/utils/global";
import {lastValueFrom} from "rxjs";
import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {appRoutePaths} from "../../../app.routes";
import {Router} from "@angular/router";
import {MenuService} from "../menu.service";
import {ToasterTypeEnum} from "../../../shared/enums/toaster-type.enum";
import {IToaster} from "../../../shared/interfaces/toaster/IToaster";
import {BrowserStorageService} from "../../../shared/services/browser-storage.service";
import {UserInterface} from "../../../shared/interfaces/user.interface";
import {UserRoleEnum} from "../../../shared/enums/user-role.enum";

@Injectable({
  providedIn: "root"
})
export class AuthService extends ApiHandlerService {
  constructor(@Inject(AppTokens.toaster) private toasterService: IToaster, protected http: HttpClient, protected menuService: MenuService, protected storageHelper: BrowserStorageService, protected router: Router) {
    super(http);
  }

  async login(userName: string, password: string, domainUrl: string) {
    return lastValueFrom(this.Post(API_ENDPOINTS.login, {userName, password, domainUrl}));
  }

  async impersonate(userName: string, isImpersonating: boolean, isBackFromImpersonating: boolean, domainUrl: string) {
    let res = await lastValueFrom(this.Post(API_ENDPOINTS.impersonate, {
      userName,
      domainUrl,
      isImpersonating,
      isBackFromImpersonating
    }));

    if (res.isSuccessful) {
      this.storageHelper.Add(AppConstants.browserStorage.token, res.response);
      await this.router.navigate([appRoutePaths.dashboard.v3]);
      location.reload();
    } else {
      this.toasterService.show(ToasterTypeEnum.Error, 'Error', res.response);
    }
  }

  async logout() {
    this.storageHelper.Delete(AppConstants.browserStorage.token);
    this.menuService.clearActiveMenu();
    this.menuService.clearActiveSubMenu();
    await this.router.navigate([`/${appRoutePaths.login.v3}`]);
    //location.reload();
  }

  public isAuthenticated(): boolean {
    let accessToken = this.getAccessToken();
    return !!accessToken?.length;
  }

  public getAccessToken(): string {
    return this.storageHelper.Get(AppConstants.browserStorage.token);
  }

  getUserModal(): UserInterface {
    let token = this.parseJwt(this.getAccessToken());
    if (token) {
      let modal = token as UserInterface;
      modal.IsPartner = modal.IsPartner == "True" ? true : false,
      modal.IsFranchiseCompany = modal.IsFranchise == "True";
      return modal;
    }
    return null;
  }

  async forgotPassword(email: string | undefined, username: string | undefined, url: string | undefined) {
    return lastValueFrom(this.Get(API_ENDPOINTS.forgotPassword + '?email=' + encodeURIComponent('' + email) + "&username=" + encodeURIComponent('' + username) + "&url=" + url));
  }

  async forgotPasswordOtpVerification(otp: string, userGuid: string, portalUrl: string) {
    return lastValueFrom(this.Get(API_ENDPOINTS.forgotPasswordOtpVerification + '?otp=' + otp + "&userGuid=" + userGuid + "&url=" + portalUrl));
  }

  async resetPassword(data: any) {
    return lastValueFrom(this.Post(API_ENDPOINTS.resetPassword, data));
  }

  getResetPassword(email: string | undefined) {
    return lastValueFrom(this.Get(API_ENDPOINTS.forgotPassword + '?email=' + encodeURIComponent('' + email)));
  }

  isStudent() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.FranchiseStudent || Number(userModal.Role) == UserRoleEnum.BusinessStudent;
  }

  isParent() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.FranchiseParent || Number(userModal.Role) == UserRoleEnum.BusinessParent;
  }

  IsImpersonating() {
    const userModal = this.getUserModal();
    return Boolean(userModal.IsImpersonating == "True");
  }

  isInstructor() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.FranchiseInstructor || Number(userModal.Role) == UserRoleEnum.BusinessInstructor;
  }

  isCoordinator() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.BusinessCoordinator || Number(userModal.Role) == UserRoleEnum.FranchiseCoordinator;
  }

  isExecutive() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.BusinessExecutive || Number(userModal.Role) == UserRoleEnum.FranchiseExecutive;
  }

  isAdmin() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.FranchiseAdmin || Number(userModal.Role) == UserRoleEnum.BusinessAdmin;
  }

  isOwner() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.BusinessAdmin;
  }

  isFranchiseAdmin() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.FranchiseAdmin;
  }

  isBusinessAdmin() {
    const userModal = this.getUserModal();
    return Number(userModal.Role) == UserRoleEnum.BusinessAdmin;
  }

  isFranchiseSecureUrl(url: string): boolean {
    const baseFranchiseUrl = new URL(this.getUserModal().FranchiseSecureUrl).origin;
    return (url || '').startsWith(baseFranchiseUrl || '');
  }

  sendActivationEmail(companyGuid: string, userGuid: string) {
    return lastValueFrom(this.Post(API_ENDPOINTS.accountActivation, {companyGuid, userGuid}));
  }

  verifyActivationEmail(companyGuid: string, activationCode: string) {
    return lastValueFrom(this.Post(API_ENDPOINTS.verifyActivationEmail, {companyGuid, activationCode}));
  }

  private parseJwt(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } catch {
      return "";
    }
  }
}
