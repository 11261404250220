export const environment = {
  api_url: 'https://angular-api.calibermatrix.com/',
  mapbox: {
    accessToken:
      'pk.eyJ1Ijoic2F1ZmlrIiwiYSI6ImNraXk3OWlpeTA0MHgycXM0ajhhemMweGIifQ.zwP1AfA5Xy0FNFLRMjntFg',
  },
  recaptcha: {
    recaptchaPublickey: '6LfuY3cUAAAAAE6r_k0XsEyI1Ia_VUhne_K27SEb',
  },
  mapbaseUrl: 'https://api.mapbox.com/',
};
