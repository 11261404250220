import {environment} from "../../../environments/environment";
import {appRoutePaths} from "../../app.routes";
import {ActivityFontDto} from "../interfaces/activity-finder/IActivityFinder.interface";

export const API_ENDPOINTS = {
  v2AutoLogin: 'Account/appAutoLogin',
  systemConfig: {
    getBusinessProfile:
      environment.api_url + 'api/SystemConfig/getCompanyWithAddress',
    saveBusinessProfile:
      environment.api_url + 'api/SystemConfig/updateBusinessProfile',
    getUserConfiguration:
      environment.api_url + 'api/SystemConfig/getUserConfigurations',
    saveUserConfiguration:
      environment.api_url + 'api/SystemConfig/updateUserConfigurations',

    // integrations:
    getDriveConfig:
      environment.api_url + 'api/SystemConfig/getGoogleDriveConfig',
    saveDriveConfig:
      environment.api_url + 'api/SystemConfig/saveGoogleDriveConfig',
    getZoomConfig: environment.api_url + 'api/SystemConfig/getZoomConfig',
    saveZoomConfig: environment.api_url + 'api/SystemConfig/saveZoomConfig',
    getQuickbookConfig:
      environment.api_url + 'api/SystemConfig/getQuickbookConfig',
    saveQuickbookConfig:
      environment.api_url + 'api/SystemConfig/saveQuickbookConfig',
    getDropBoxConfig: environment.api_url + 'api/SystemConfig/getDropBoxConfig',
    saveDropBoxConfig:
      environment.api_url + 'api/SystemConfig/saveDropBoxConfig',
    getBirdEyeConfig: environment.api_url + 'api/SystemConfig/getBirdEyeConfig',
    saveBirdEyeApi: environment.api_url + 'api/SystemConfig/saveBirdEyeApi',
    saveBusinessIds: environment.api_url + 'api/SystemConfig/saveBusinessIds',
    //stripe
    saveStripeConfig: environment.api_url + 'api/SystemConfig/saveStripeConfig',
    savePlaidConfig: environment.api_url + 'api/SystemConfig/savePlaidConfig',
    saveNmiConfig: environment.api_url + 'api/SystemConfig/saveNmiConfig',
    getTransactionConfig:
      environment.api_url + 'api/SystemConfig/getTransactionConfig',
    //LanguageSettings
    getLanguageSettings:
      environment.api_url + 'api/SystemConfig/getLanguageCulture',
    saveLanguageSettings:
      environment.api_url + 'api/SystemConfig/saveLanguageCulture',
    //TimeZones
    getTimeZone: environment.api_url + 'api/SystemConfig/getTimeZone',
    saveTimeZone: environment.api_url + 'api/SystemConfig/saveTimeZone',
  },
  department: {
    getDepartments: environment.api_url + 'api/Department/getDepartments',
    saveDepartment: environment.api_url + 'api/Department/saveDepartments',
    deleteDepartment: environment.api_url + 'api/Department/deleteDepartments',
  },
  manageCertificate: {
    getCertificate:
      environment.api_url +
      'api/ManageCertificateTemplate/getCertificateTemplate',
    saveCertificate:
      environment.api_url +
      'api/ManageCertificateTemplate/addUpdateCertificateTemplate',
  },
  studentRelated: {
    getDefaultOnlineClass:
      environment.api_url + 'api/SystemConfig/GetDefaultOnlineClass',
    setDefaultOnlineClass:
      environment.api_url + 'api/SystemConfig/SetDefaultOnlineClass',
    getBoardStatusSetting:
      environment.api_url + 'api/SystemConfig/GetBoardStatusSetting',
    saveBoardStatusSetting:
      environment.api_url + 'api/SystemConfig/SaveBoardStatusSetting',
    getGpaConfiguration:
      environment.api_url + 'api/SystemConfig/GetGpaConfiguration',
    getGradingConfiguration:
      environment.api_url + 'api/SystemConfig/GetGradingConfiguration',
    saveGpaConfiguration:
      environment.api_url + 'api/SystemConfig/SaveGpaConfiguration',
    getGradingFormData:
      environment.api_url + 'api/SystemConfig/GetGradingConfigFormData',
    saveGradingFormData:
      environment.api_url + 'api/SystemConfig/SaveGradingConfigFormData',
    deleteGrading: environment.api_url + 'api/SystemConfig/DeleteGrading',
    getRubricGrid:
      environment.api_url + 'api/SystemConfig/GetAssessmentRubricGrid',
    saveRubric: environment.api_url + 'api/SystemConfig/SaveAssessmentRubric',
    verifyRubric:
      environment.api_url + 'api/SystemConfig/VerifyRubricEditPermission',
    deleteRubric:
      environment.api_url + 'api/SystemConfig/RemoveAssessmentRubric',
    copyRubric: environment.api_url + 'api/SystemConfig/CopyFromExistingRubric',
    getAssessmentConfig:
      environment.api_url + 'api/SystemConfig/GetAssessmentConfiguration',
    saveAssessmentConfig:
      environment.api_url + 'api/SystemConfig/SaveAssessmentConfiguration',
    autoAssignStep:
      environment.api_url + 'api/SystemConfig/SaveAutoStepConfiguration',
    autoAssignChallenge:
      environment.api_url + 'api/SystemConfig/SaveAutoChallengeConfiguration',
    applyToAllFranchise:
      environment.api_url +
      'api/SystemConfig/applyClassConfigSettingsToAllFranchises',
    getClassConfig:
      environment.api_url + 'api/SystemConfig/getClassConfiguration',
    setClassConfig:
      environment.api_url + 'api/SystemConfig/saveUpdateCompanyConfiguration',
    getPageConfig: environment.api_url + 'api/SystemConfig/getSecureSettings',
    saveFontColors:
      environment.api_url +
      'api/SystemConfig/saveCourseClassesTagsColorSettings',
    getClassColorConfig:
      environment.api_url + 'api/SystemConfig/getClassWidgetColor',
    saveClassColorConfig:
      environment.api_url + 'api/SystemConfig/saveDynamicColors',
  },
  staffRelated: {
    getWeekStartDay: environment.api_url + 'api/SystemConfig/GetWeekStartDay',
    saveWeekStartDay: environment.api_url + 'api/SystemConfig/SaveWeekStartDay',
  },
  addonConfig: {
    getList: environment.api_url + 'api/AddOnsManage/getFeeList',
    saveAddon: environment.api_url + 'api/AddOnsManage/addUpdateFee',
    deleteAddon: environment.api_url + 'api/AddOnsManage/deleteNewFee',
    getAddon: environment.api_url + 'api/AddOnsManage/getNewFee',
  },
  activityFinder: {
    getData: environment.api_url + 'api/ActivityFinder/ActivitiesList',
    saveStyle: environment.api_url + 'api/ActivityFinder/SaveFontStyle',
    saveTabName: environment.api_url + 'api/ActivityFinder/SaveTabName',
    showHide: environment.api_url + 'api/ActivityFinder/HideShowTab',
    setTabView: environment.api_url + 'api/ActivityFinder/setTabView',
    deleteTab: environment.api_url + 'api/ActivityFinder/DeleteTab',
    addToFranchise: environment.api_url + 'api/ActivityFinder/AddToFranchise',
    reorderRow: environment.api_url + 'api/ActivityFinder/SaveOrder',
    saveType: environment.api_url + 'api/ActivityFinder/SaveType',
    saveCategory: environment.api_url + 'api/ActivityFinder/SaveCategory',
    getTypes: environment.api_url + 'api/ActivityFinder/GetTypeList',
    getCategories: environment.api_url + 'api/ActivityFinder/GetCategoryList',
    getEmbeddedCode: environment.api_url + 'api/ActivityFinder/GetEmbeddedCode',
  },
  copyModule: {
    getSyncStatus: environment.api_url + 'api/Copy/getSyncSetting',
    getCopyItems: environment.api_url + 'api/Copy/getCopyItems',
    copyToFranchise: environment.api_url + 'api/Copy/copyToFranchises',
    saveSyncSettings: environment.api_url + 'api/Copy/updateAutoSyncSetting',
  },
  company: {
    configs: environment.api_url + 'api/Company/CompanyConfigs',
    menus: environment.api_url + 'api/Company/menus',
    permissions: environment.api_url + 'api/Company/permissions',
    franchises: environment.api_url + 'api/Company/franchises',
    stripeConnectUrl: environment.api_url + 'api/Company/StripeConnectUrl',
    generateStripeAccountId:
      environment.api_url + 'api/Company/GenerateStripeAccountId',
    getCompaniesWithLocations: environment.api_url + 'api/Company/GetCompanyLocations',
    deleteCompanyLocations : environment.api_url + 'api/Company/DeleteCompanyLocation',
    saveCompanyLocations : environment.api_url + 'api/Company/SaveCompanyLocation',
    SetGeofencing : environment.api_url + 'api/Company/EnableDisableGeofencing',
  },
  students: {
    getStudentGrid: environment.api_url + 'api/Student/students',
    getEnrollmentDropdown:
      environment.api_url + 'api/Student/getEnrollmentDropdown',
    updateStudentStatus: environment.api_url + 'api/Student/setStudentStatus',
  },
  userGrid: environment.api_url + 'api/User/GetGridUsers',
  families: {
    getFamilyGrid: environment.api_url + 'api/Families/families',
  },
  userInvitationForm: {
    getForm: environment.api_url + 'api/UserInvitation/GetUserInviteForm',
    saveForm: environment.api_url + 'api/UserInvitation/UserInvitationFormSubmission',

  },
  franchiseAdminInvitation: {
    getForm: environment.api_url + 'api/UserInvitation/GetFranchiseAdminForm',
    saveForm: environment.api_url + 'api/UserInvitation/SaveFranchiseAdminForm',
  },
  articles: {
    getArticleDetails: environment.api_url + 'api/EmbeddedScript/GetArticleDetails',
    saveFeedback: environment.api_url + 'api/EmbeddedScript/SaveArticleDetails',
    PubliclyListArticles: environment.api_url + 'api/Articles/PubliclyList',
    PubliclySaveFeedback: environment.api_url + 'api/EmbeddedScript/PubliclySaveArticleDetails'
  },
  embeddedForm: {
    getForm: environment.api_url + 'api/EmbeddedScript/GetEmbeddedForm',
    getContactForm: environment.api_url + 'api/EmbeddedScript/GetEmbeddedContactForm',
    getContactFormByGuid: environment.api_url + 'api/EmbeddedScript/GetEmbeddedContactFormByCmpGuid',
    saveContactForm: environment.api_url + 'api/EmbeddedScript/SaveEmbeddedContactForm',
    getEnrollmentForm: environment.api_url + 'api/EmbeddedScript/GetEmbeddedEnrollmentForm',
    getEnrollmentFormByCmpGuid: environment.api_url + 'api/EmbeddedScript/GetEmbeddedEnrollmentFormByCmpGuid',
    saveEnrollmentForm: environment.api_url + 'api/EmbeddedScript/SaveEmbeddedEnrollmentForm',
    saveForm: environment.api_url + 'api/EmbeddedScript/SaveEmbeddedForm',
    getRedirectionPath: environment.api_url + 'api/EmbeddedScript/GetRedirectionPathForShortUrl',
    viewCertificate: environment.api_url + 'api/EmbeddedScript/ViewCertificate',
  },
  updateUserPin: environment.api_url + 'api/user/UpdateUserPin',
  kiosk: {
    getKioskList: environment.api_url + 'api/Kiosk/List',
    saveKiosk: environment.api_url + 'api/Kiosk/Save',
    delteKiosk: environment.api_url + 'api/Kiosk/Delete',
    syncToAllFranchise: environment.api_url + 'api/Kiosk/Sync',
  },
  uploadFile: environment.api_url + 'api/FileUpload',
  tableFilters: {
    getViews: environment.api_url + 'api/GridCustomFilter/views',
    saveView: environment.api_url + 'api/GridCustomFilter/saveView',
    deleteView: environment.api_url + 'api/GridCustomFilter/deleteView',
    shareView: environment.api_url + 'api/GridCustomFilter/shareView',
    getSharedRoles: environment.api_url + 'api/GridCustomFilter/roles',
  },
  boards: {
    getFilterValue: environment.api_url + 'api/Board/getFilterTypeValue',
    getOptionValue: environment.api_url + 'api/Board',
  },
  courseTypes: {
    getCourseTypes: environment.api_url + 'api/CourseType/list',
  },
  courseCategories: {
    getCourseCategories:
      environment.api_url + 'api/CourseCategory/getCourseCategoriesByTypeId',
  },
  transferReason: {
    getList:
      environment.api_url +
      'api/ManageDropTransferReason/getDropTransferReasonsList',
    delete:
      environment.api_url +
      'api/ManageDropTransferReason/deleteDropTransferReason',
    save:
      environment.api_url +
      'api/ManageDropTransferReason/addUpdateDropTransferReason',
  },
  userPolicy: {
    getList: environment.api_url + 'api/ManagePolicy/getPoliciesList',
    delete: environment.api_url + 'api/ManagePolicy/deletePolicy',
    save: environment.api_url + 'api/ManagePolicy/addUpdatePolicy',
    edit: environment.api_url + 'api/ManagePolicy/getPolicy',
    setStatus: environment.api_url + 'api/ManagePolicy/setPolicyStatus',
    getUnansweredPolicies: environment.api_url + 'api/ManagePolicy/getUnansweredPolicies',
    addPolicyAgreement: environment.api_url + 'api/ManagePolicy/addPolicyAgreement',
  },
  couponConfig: {
    getCoupons: environment.api_url + 'api/CouponsManage/GetCompanyCouponsList',
    saveCoupons: environment.api_url + 'api/CouponsManage/addUpdateCoupon',
    deleteCoupons: environment.api_url + 'api/CouponsManage/deleteCoupon',
    editCoupons: environment.api_url + 'api/CouponsManage/getCompanyCoupon',
  },
  central: environment.api_url + 'api/courses/central',
  getCoursesByTypeAndCategory:
    environment.api_url + 'api/Courses/getCoursesByTypeAndCategory',
  companyNameByUserName:
    environment.api_url + 'api/Company/CompanyNameByUserName',
  login: environment.api_url + 'api/Auth/appLogin',
  accountActivation: environment.api_url + 'api/Auth/SendActivationEmail',
  verifyActivationEmail: environment.api_url + 'api/Auth/VerifyActivationEmail',
  impersonate: environment.api_url + 'api/User/impersonate',
  checkUsername: environment.api_url + 'api/User/checkUsernameAvailability',
  saveUser: environment.api_url + 'api/User/SaveUser',
  inviteUser: environment.api_url + 'api/User/InviteUser',
  deleteUser: environment.api_url + 'api/User/DeleteUser',
  forgotPassword: environment.api_url + 'api/Auth/ForgotPassword',
  forgotPasswordOtpVerification:
    environment.api_url + 'api/Auth/ForgotPasswordOtpVerification',
  resetPassword: environment.api_url + 'api/Auth/ResetPassword',
  familiesGridCountEndpoint: environment.api_url + 'api/Families/count',
  familiesGridListEndpoint: environment.api_url + 'api/Families/families',
  getUserColumnSettingsEndpoint:
    environment.api_url + 'api/GridColumnSettings/UserColumnSettings',
  updateColumnSettingsEndpoint:
    environment.api_url + 'api/GridColumnSettings/updateColumnWidth',
  updateColumnOrderEndpoint:
    environment.api_url + 'api/GridColumnSettings/updateColumnOrder',
  updateColumnVisibilityEndpoint:
    environment.api_url + 'api/GridColumnSettings/updateColumnVisibility',
  getUserPin: environment.api_url + 'api/User/getUserPinAsnyc',

  dashboard: {
    widgets: environment.api_url + 'api/dashboard/widgets',
    saveSettings: environment.api_url + 'api/dashboard/settings',
    currentMonthWidgetsCount:
      environment.api_url + 'api/dashboard/currentMonthWidgetsCount',
    trioWidgetsCount: environment.api_url + 'api/dashboard/trioWidgetsCount',
    enrollmentPerformance:
      environment.api_url + 'api/dashboard/enrollmentPerformance',
    enrollmentComparison:
      environment.api_url + 'api/dashboard/enrollmentComparison',
    partnerEnrollments:
      environment.api_url + 'api/dashboard/partnerEnrollments',
    classDropOffCountByType:
      environment.api_url + 'api/dashboard/classDropOffCountByType',
    eventDropOffCountByType:
      environment.api_url + 'api/dashboard/eventDropOffCountByType',
    classEnrollmentCountByType:
      environment.api_url + 'api/dashboard/classEnrollmentCountByType',
    eventEnrollmentCountByType:
      environment.api_url + 'api/dashboard/eventEnrollmentCountByType',
    activeCourseCountByType:
      environment.api_url + 'api/dashboard/getActiveCourseCountByType',
    listOfLeadsCount: environment.api_url + 'api/dashboard/listOfLeadsCount',
    topFranchiseByRevenue:
      environment.api_url + 'api/dashboard/topFranchisesByRevenue',
    calendar: environment.api_url + 'api/dashboard/calendar',
    leadsGeneration: environment.api_url + 'api/dashboard/leadsGeneration',
    studentDemographics:
      environment.api_url + 'api/dashboard/studentDemographics',
    studentAttendance: environment.api_url + 'api/dashboard/studentAttendance',
    classEnrollmentRevenueByType:
      environment.api_url + 'api/dashboard/classEnrollmentRevenueByType',
    externalLink: environment.api_url + 'api/dashboard/externalLink',
    announcements: environment.api_url + 'api/dashboard/announcements',
    map: environment.api_url + 'api/dashboard/map',
    restoreWidgetsToDefault:
      environment.api_url + 'api/dashboard/restoreWidgetsToDefault',
    getStudentPointsCounter:
      environment.api_url + 'api/dashboard/getStudentPointsCounter',
  },
  types: {
    list: environment.api_url + 'api/CourseType/list',
  },
  EventTypes: {
    list: environment.api_url + 'api/EventType/list',
  },
  Teminologies: {
    list: environment.api_url + 'api/SystemConfig/getTerminologiesList',
    update: environment.api_url + 'api/SystemConfig/UpdateTerminology',
  },
  // email Configs
  CompanyEmailConfig: {
    saveSenderEmailConfigs:
      environment.api_url + 'api/SystemConfig/saveEmailSenderConfigs',
    getSenderEmailConfigs:
      environment.api_url + 'api/SystemConfig/getEmailSenderConfigs',
    getEmailFrequencies:
      environment.api_url + 'api/SystemConfig/getEmailFrequencies',
    getEmailFrequenciesSetting:
      environment.api_url + 'api/SystemConfig/getEmailFrequencySetting',
    setEmailFrequency:
      environment.api_url + 'api/SystemConfig/setEmailFrequency',
    setEmailFrequencySettings:
      environment.api_url + 'api/SystemConfig/setFrequencyEmailSettings',
    getTimeTrackerEmailSettings:
      environment.api_url + 'api/SystemConfig/getTimeTrackerEmailSettings',
    setTimeTrackingEmailSettings:
      environment.api_url + 'api/SystemConfig/setTimeTrackingEmailSettings',
    getEmailsSettings:
      environment.api_url + 'api/SystemConfig/GetEmailSettings',
    setEmailSettingsValues:
      environment.api_url + 'api/SystemConfig/setEmailSettingsValues',
    getChallengeNotificationToStaff:
      environment.api_url + 'api/SystemConfig/getChallengeNotificationToStaff',
    saveCompletionEmailAt:
      environment.api_url +
      'api/SystemConfig/SaveChallengeCompletionEmailPoints',
    getHomeworkAndAssessmentsDueDateNotification:
      environment.api_url +
      'api/SystemConfig/GetHomeworkAndAssessmentDueDateNotification',
    getSystemEmailFooter:
      environment.api_url + 'api/SystemConfig/getSystemEmailFooter',
    SaveSystemEmailFooter:
      environment.api_url + 'api/SystemConfig/SaveSystemEmailFooter',
    getCompletionEmailImage:
      environment.api_url + 'api/SystemConfig/getCompletionEmailImage',
    addUpdateCompletionImage:
      environment.api_url + 'api/SystemConfig/addUpdateCompletionImage',
  },
  //platform branding
  PlatformConfig: {
    getPlatformConfig:
      environment.api_url + 'api/SystemConfig/getPlatformConfig',
    saveCompanyLogo: environment.api_url + 'api/SystemConfig/saveCompanyLogo',
    resetToDefaultCompanyLogo:
      environment.api_url + 'api/SystemConfig/resetToDefaultLogo',
    saveLoginPageImage:
      environment.api_url + 'api/SystemConfig/saveLoginPageImage',
    resetToDefaultLoginImage:
      environment.api_url + 'api/SystemConfig/resetToDefaultLoginImage',
    saveMenuBarLogoBgColor:
      environment.api_url + 'api/SystemConfig/SaveMenuBarLogoBackgroundColor',
    saveFrachiseSecureLogoBgColor:
      environment.api_url +
      'api/SystemConfig/SaveFranchiseSecureLogoBackgroundColor',
    saveFavIcon: environment.api_url + 'api/SystemConfig/saveFavIcon',
    resetToDefaultFavIcon:
      environment.api_url + 'api/SystemConfig/resetToDefaultFavIcon',
    saveLoginLogo: environment.api_url + 'api/SystemConfig/saveLoginLogo',
    resetToDefaultLoginLogo:
      environment.api_url + 'api/SystemConfig/resetToDefaultLoginLogo',
    saveBrandingTitle:
      environment.api_url + 'api/SystemConfig/saveBrandingTitle',
  },
  sendMobileAppConfiEmail:
    environment.api_url + 'api/SystemConfig/sendMobileAppConfigEmail',
  sendEmailForCustomURL:
    environment.api_url + 'api/SystemConfig/sendEmailForCustomURL',
  UserGuideSection: {
    saveUserGuide: environment.api_url + 'api/Onboarding/save',
    getUserGuide: environment.api_url + 'api/Onboarding/get',
    saveCompanyOnBoardingCompletion:
      environment.api_url + 'api/Onboarding/saveCompanyOnBoardingCompletion',
    getCompanyOnboardingSettings:
      environment.api_url + 'api/Onboarding/GetCompanyOnboardingSettings',
  },

  UserProfile: {
    saveProfileBasicInfo:
      environment.api_url + 'api/profile/saveProfileBasicInfo',
    getProfileBasicInfo:
      environment.api_url + 'api/profile/getProfileBasicInfo',
    changeUserPassword: environment.api_url + 'api/profile/changeUserPassword',
    setEmailPreferences:
      environment.api_url + 'api/profile/setEmailPreferences',
    getUserProfileImage:
      environment.api_url + 'api/profile/getUserProfileImage',
    updateUserProfileImage:
      environment.api_url + 'api/profile/updateUserProfileImage',
    getEmailPreferences:
      environment.api_url + 'api/profile/getEmailPreferences',
  },
  Notification: {
    getUserNotifications:
      environment.api_url + 'api/notification/getUserNotifications',
    getUserActivityLogs:
      environment.api_url + 'api/notification/getUserActivityLogs',
    clearNotification:
      environment.api_url + 'api/notification/clearAllNotification',
    visitNotification:
      environment.api_url + 'api/notification/visitNotification',
    getNotificationsCount:
      environment.api_url + 'api/notification/getNoticationCounts',
  },
  Roles: {
    getRolesGird: environment.api_url + 'api/PortalUserRoles/getRolesGird',
    addUpdateRoles: environment.api_url + 'api/PortalUserRoles/addUpdateRoles',
    deleteRole: environment.api_url + 'api/PortalUserRoles/deleteRole',
  },
  UserAccess: {
    getUserAccessGird:
      environment.api_url + 'api/PortalUserRoles/getUserAccessGird',
    addUpdateRolesAccess:
      environment.api_url + 'api/PortalUserRoles/AddUpdateRoleAccess',
    deleteRoleAccess:
      environment.api_url + 'api/PortalUserRoles/deleteRoleAccess',
  },
  Classes: {
    onlineClasses: environment.api_url + 'api/classes/online-classes',
    joinClass: environment.api_url + 'api/classes/join-class',
    getClassesByCourseIds:
      environment.api_url + 'api/Classes/getCourseTypeClasses',
  },

  AttachmentsPermissions: {
    getAttachmentsPermision:
      environment.api_url + 'api/permissions/getAttachmentsPermissions',
    saveAttachmentsPermision:
      environment.api_url + 'api/permissions/SaveAttachmentsPermissions',
  },
  ReportPermissions: {
    getReportPermisions:
      environment.api_url + 'api/permissions/getReportPermissions',
    saveReportPermisions:
      environment.api_url + 'api/permissions/saveReportPermisions',
  },
  SMSConfigs: {
    getSmsConfigs: environment.api_url + 'api/SystemConfig/getSMSConfig',
    saveSmsConfigs:
      environment.api_url + 'api/SystemConfig/SaveSMSConfiguration',
    getSMSSettings: environment.api_url + 'api/SystemConfig/getAllSmsSettings',
    saveSmsStatusSettings:
      environment.api_url + 'api/SystemConfig/saveSmsStatusSettings',
    saveSMSAccess: environment.api_url + 'api/SystemConfig/saveSMSAccess',
    saveSmsMessageSettings:
      environment.api_url + 'api/SystemConfig/saveSmsMessageSettings',
  },
  PortalAccess: {
    getPortalEmailAccessConfig:
      environment.api_url + 'api/SystemConfig/getPortalAccessConfig',
    saveSendPortalAccessConfig:
      environment.api_url + 'api/SystemConfig/SaveSendPortalAccessConfig',
    portalAccessEmailConfigForFranchises:
      environment.api_url +
      'api/SystemConfig/portalAccessEmailConfigForFranchises',
  },
  uploadImageAndGetUrl:
    environment.api_url + 'api/SystemConfig/uploadImageAndGetUrl',
  AdminAudits: {
    getListAdminAudits: environment.api_url + 'api/AdminAudit/list'
  }
};

export const EXPOSED_URL = [
  appRoutePaths.externalArticles.v3
];

export const AppConstants = {
  certificateLogo: 'https://core-lms.calibermatrix.com/assets/Canvas/images/001-analytics.png',
  certificateLogoBg: 'https://core-lms.calibermatrix.com/assets/Canvas/images/certificate-bg.png',
  certificateBg: 'https://core-lms.calibermatrix.com/assets/netTemp.png',
  embeddedScriptCdn: 'https://calimaticfrontdoor-dzd3dydygqbfdufq.z01.azurefd.net/calimatic-lmslive6-secure-cdn',
  logicOperator:
    [
      {label: 'AND', value: 'and'},
      {label: 'OR', value: 'or'}
    ]
  ,
  exportFileOptions: [
    {
      name: 'Export as CSV',
      icon: 'pi pi-file',
      key: 'csv',
      isIcon: true
    },
    {
      name: 'Export as PDF',
      icon: 'pi pi-file-pdf',
      key: 'pdf',
      isIcon: true
    },
    {
      'name': 'Export as XLSX',
      'icon': 'pi pi-file-excel',
      'key': 'xlsx',
      'isIcon': true
    }
  ],
  regularExpressions: {
    space: /(?!^ +$)^.+$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    domain: /^[a-zA-Z][^\s]*[a-zA-Z0-9]$/,
    url: /\b((http|https):\/\/[^\s/$.?#].[^\s]*)\b/,
    phoneNumber: /^(?:\+?\d{1,3})?[-.\s]?(\(?\d{1,4}\)?[-.\s]?)?\d{1,15}$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~ ]{8,}$/,
    restrictSpecialCharacters: /^(?=.*[a-zA-Z0-9])([a-zA-Z0-9._-]+|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
  },
  appUrls: {
    zoomConfig: 'https://help.calimaticedtech.com/en/articles/8876604-zoom-integration-configuration',
    googleDriveConfig: 'https://help.calimaticedtech.com/en/articles/8876732-google-drive-configuration',
    dropBoxConfig: 'https://help.calimaticedtech.com/en/articles/8876736-dropbox-configuration',
    stripeDashboardUrl: 'https://dashboard.stripe.com',
    plaidDashboardUrl: 'https://dashboard.plaid.com',
    nmiAccountUrl: 'https://secure.nmi.com',
    calimaticKB: 'https://help.calimaticedtech.com/'
  },
  TimeZoneNoteMessage: "You cannot change the Time Zone once you set it and create at least one class with the recurring cycle. Please contact support@calimaticedtech.com for any change if the setting is disabled.",
  browserStorage: {
    token: 'token',
    activeMenu: 'activeMenu',
    activeSubMenu: 'activeSubMenu',
    appVersion: 'appVersion'
  },
  defaultMenuResize: 992,
  defaultCellHeight: 40,
  defaultSkeletonLoaderRows: 18,
  globalStyles: {
    primaryColor: '--primary-color',
    fontSize: '14px;',
    fontFamily: 'DM Sans", sans-serif'
  },
  map: {
    marker: '/assets/images/icons/marker-star.svg',
    borderColor: '#ffffff',
    satelliteView: 'mapbox://styles/mapbox/satellite-v9',
    streetView: 'mapbox://styles/mapbox/streets-v11',
    defaultLocation: {
      lng: -98.35,
      lat: 39.50
    },
    zoomLevel: 2
  },
  customerSupportMail: 'support@calimaticedtech.com',
  defaultUserProfileImage: '../../../../../assets/images/default-user-image.svg',
  marketPlacePublishStepsVideo: '../../../../../assets/video/MarketplaceSteps.mp4',
  showUserGuide: 'showUserGuide',
  dateTimeFormat: "MMM/dd hh:mm tt",
  calimaticHelpUrl: 'https://help.calimaticedtech.com/en/collections/8029412-getting-started',
  defaultConfigs: {
    favIcon: "https://calibermatrixc3451.blob.core.windows.net/company-1/CM_507bbda8-0761-47ae-bbc6-344f720c9166.png",
    companyLogo: "https://calimaticstore.blob.core.windows.net/company-1536/CM_17e696fa-845e-41da-891d-70e0227d896c.png",
    loginLogo: "https://calimaticstore.blob.core.windows.net/company-1536/CM_a7deaa0e-37c3-40ab-8990-aa5a6f21c200.png",
    loginImage: "https://calimaticstore.blob.core.windows.net/company-1/CM_90542d7f-1c2b-4746-909f-50ddc6d52056.png",
    loginBgColor: "#ffffff",
    loginBgColorRight: "#ffffff",
    loginFontColor: "#000000",
    loginImageSize: "cover",
    brandingTitle: "Calimatic",
    brandingFavicon: "https://calimaticstore.blob.core.windows.net/company-1536/CM_a6bd03ab-3d78-4578-9447-78ffae634a26.ico",
    loginTemplate: "standard",
    loginImageType: "image",
    loginButtonColor: "#0070d2",
    tokenExpiry: "43200", //minutes (30 days)
    bodyFontSize: "13.5px",
    primaryColor: "#1271c4",
    fontFamily: "Open Sans",
    challengeCompletion: "https://calimaticfrontdoor-dzd3dydygqbfdufq.z01.azurefd.net/code-hero-cdn/Content/EmailImages/Challenge.png",
    levelCompletion: "https://calimaticfrontdoor-dzd3dydygqbfdufq.z01.azurefd.net/code-hero-cdn/Content/EmailImages/BatchImage.jpg",
    courseCompletion: "https://calimaticfrontdoor-dzd3dydygqbfdufq.z01.azurefd.net/code-hero-cdn/Content/EmailImages/Course-completion.png"
  }
}
export const AppTokens = {
  toaster: 'toaster',
  confirmation: 'confirmation'
}
export const AppVideos = {
  dashboard: 'https://calimaticstore.blob.core.windows.net/learningvvideos/dashboardlearn.mp4',
  activityFinder: 'https://calimaticstore.blob.core.windows.net/learningvvideos/activityfinderlearn.mp4'
}
export const DefaultActivityFinderFont: ActivityFontDto = {
  tabColor: "#0061f7",
  tabRadius: 10,
  color: "#fff",
  size: 18,
  isBold: true,
  isItalic: false,
  isUnderline: false,
  family: "Roboto, sans-serif"
}

export const Colours_Palette = {
  deepOceanBlue: '#00589c',
  turquoiseBliss: '#3dc6c3',
  skylineBlue: '#1891c3',
  aquaBreeze: '#3ac0da',
  royalAzure: '#016fc4',
  mintLagoon: '#50e3c2'
}
