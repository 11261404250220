import {Injectable} from "@angular/core";
import {API_ENDPOINTS, AppConstants} from "../../shared/utils/global";
import {BehaviorSubject, lastValueFrom} from "rxjs";
import {CompanyConfigDto} from "../../shared/dtos/company-config-dto";
import {ApiHandlerService} from "./base/api-handler.service";
import {PermissionsInterface} from "../../shared/interfaces/permissions.interface";
import {DropdownDto} from "../../shared/dtos/dropdown-dto";
import { LocationDto } from "../../shared/interfaces/geofencing/IGeoFencing.interface";

@Injectable({
  providedIn: "root"
})
export class CompanyService {
  private headerVisibility$ = new BehaviorSubject(true);

  constructor(private apiService: ApiHandlerService) {
  }

  async getConfigs(url: string) {
    let res = await lastValueFrom(this.apiService.Get(API_ENDPOINTS.company.configs, {url}));
    if (res.isSuccessful) {
      let configs = res.response as CompanyConfigDto;
      if (configs) {
        configs.isWhiteLabeled = (configs.loginLogo || '').length > 0 || (configs.companyLogo || '').length > 0 || (configs.loginImage || '').length > 0;
        configs.loginTemplate = !configs.loginTemplate ? AppConstants.defaultConfigs.loginTemplate : configs.loginTemplate;
        configs.loginLogo = !configs.loginLogo ? (configs.companyLogo ? configs.companyLogo : AppConstants.defaultConfigs.loginLogo) : configs.loginLogo;
        configs.companyLogo = !configs.companyLogo ? AppConstants.defaultConfigs.companyLogo : configs.companyLogo;
        configs.loginImageSize = !configs.loginImageSize ? AppConstants.defaultConfigs.loginImageSize : configs.loginImageSize;
        configs.loginFontColor = !configs.loginFontColor ? AppConstants.defaultConfigs.loginFontColor : configs.loginFontColor;
        configs.loginBgColor = !configs.loginBgColor ? AppConstants.defaultConfigs.loginBgColor : configs.loginBgColor;
        configs.loginBgColorRight = !configs.loginBgColorRight ? AppConstants.defaultConfigs.loginBgColorRight : configs.loginBgColorRight;
        configs.loginButtonColor = !configs.loginButtonColor ? AppConstants.defaultConfigs.loginButtonColor : configs.loginButtonColor;
        configs.bodyFontSize = !configs.bodyFontSize ? AppConstants.defaultConfigs.bodyFontSize : configs.bodyFontSize;
        configs.brandingTitle = !configs.brandingTitle ? AppConstants.defaultConfigs.brandingTitle : configs.brandingTitle;
        configs.brandingFavicon = !configs.brandingFavicon ? AppConstants.defaultConfigs.brandingFavicon : configs.brandingFavicon;
        configs.fontFamily = !configs.fontFamily ? AppConstants.defaultConfigs.fontFamily : configs.fontFamily;
        configs.primaryColor = !configs.primaryColor ? AppConstants.defaultConfigs.primaryColor : configs.primaryColor;
        configs.companyEmail = !!configs.companyEmail ? configs.companyEmail : "";

      }
      return configs;
    }

    return null;
  }

  async getNameByUserName(userName: string, url: string) {
    let res = await lastValueFrom(this.apiService.Get(API_ENDPOINTS.companyNameByUserName, {
      userName,
      url
    }));

    if (res.isSuccessful) {
      return res.response as string;
    }
    return "";
  }

  async getPermissions(userGuid: string) {
    let res = await lastValueFrom(this.apiService.Get(API_ENDPOINTS.company.permissions, {userGuid}));
    if (res.isSuccessful) {
      return res.response as PermissionsInterface[];
    }
    return null;
  }

  async getFranchises(excludeMainBusiness =  false) {
    let res = await lastValueFrom(this.apiService.Get(API_ENDPOINTS.company.franchises, {excludeMainBusiness}));
    if (res.isSuccessful) {
      return res.response as DropdownDto[];
    }
    return [];
  }

  async getStripeConnectUrl() {
    return await lastValueFrom(this.apiService.Get(API_ENDPOINTS.company.stripeConnectUrl));
  }

  async generateStripeAccountId(code: string) {
    return await lastValueFrom(this.apiService.Post(API_ENDPOINTS.company.generateStripeAccountId, {code}));
  }

  setHeaderVisibility(value: boolean) {
    this.headerVisibility$.next(value);
  }

  onHeaderVisibilityChange() {
    return this.headerVisibility$.asObservable();
  }
  async uploadImageAndGetUrl(imageFile: File) {
    const image = new FormData();
    image.append('image', imageFile);
    return await lastValueFrom(this.apiService.Post(API_ENDPOINTS.uploadImageAndGetUrl, image));
  }
  
  async getCompaniesWithLocations() {
    return await lastValueFrom(this.apiService.Get(API_ENDPOINTS.company.getCompaniesWithLocations));
  }
  async deleteCompanyLocations(location:LocationDto) {
    return await lastValueFrom(this.apiService.Post(API_ENDPOINTS.company.deleteCompanyLocations,location));
  }
  async saveCompanyLocations(location:LocationDto) {
    return await lastValueFrom(this.apiService.Post(API_ENDPOINTS.company.saveCompanyLocations,location));
  }
  async setGeofencing(value:boolean,companyIds : number[]) {
    return await lastValueFrom(this.apiService.Post(API_ENDPOINTS.company.SetGeofencing,{value,companyIds}));
  }
}
