import {Component, effect, input, OnInit} from '@angular/core';
import {StackBarChartDataDto} from "../../../../../shared/dtos/chart-data.dto";
import {ChartModule} from "primeng/chart";
import {Colours_Palette} from "../../../../../shared/utils/global";

@Component({
  selector: 'calimatic-stack-bar-chart',
  standalone: true,
  imports: [
    ChartModule
  ],
  templateUrl: './stack-bar-chart.component.html',
  styleUrl: './stack-bar-chart.component.scss'
})
export class StackBarChartComponent implements OnInit {

  data = input.required<StackBarChartDataDto[]>();
  displayLegend = input<boolean>(false);
  barThickness = input<string>('0.9');
  label = input.required<string>();

  chartData: any;
  chartOpts: any;

  constructor() {
    effect(() => {
      if (this.data()) {
        this.prepareChartData();
      }
    });
  }

  ngOnInit() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.chartOpts = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false
        },
        legend: {
          display: this.displayLegend(),
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            //color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          stacked: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            //color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }


  private prepareChartData() {
    const labels = Array.from(new Set(this.data().map(x => x.label)));
    const keys = Array.from(new Set(this.data().map(x => x.key)));
    const colorPalette = [Colours_Palette.deepOceanBlue, Colours_Palette.turquoiseBliss, Colours_Palette.skylineBlue, Colours_Palette.aquaBreeze, Colours_Palette.royalAzure, Colours_Palette.mintLagoon];

    const datasets = keys.filter(k => k).map((x, datasetIndex) => {
      return {
        type: 'bar',
        label: x,
        backgroundColor: colorPalette[datasetIndex % colorPalette.length],
        data: labels.map(y => {
          const item = this.data().find(d => d.label === y && d.key == x);
          return item ? item.value : '';
        })
      };
    });

    this.chartData = {
      labels: labels,
      datasets: datasets
    };
  }
}
