import {Component, effect, input} from '@angular/core';
import {ChartModule} from "primeng/chart";
import {VerticalBarChartDto} from "../../../../../shared/dtos/chart-data.dto";
import {Colours_Palette} from "../../../../../shared/utils/global";

@Component({
  selector: 'calimatic-vertical-bar-chart',
  standalone: true,
  imports: [
    ChartModule
  ],
  templateUrl: './vertical-bar-chart.component.html',
  styleUrl: './vertical-bar-chart.component.scss'
})
export class VerticalBarChartComponent {
  data = input.required<VerticalBarChartDto[]>();

  chartData: any;
  chartOpts: any;

  constructor() {
    effect(() => {
      if (this.data()) {
        this.preparedChartData();
      }
    });
  }

  private preparedChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--p-text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--p-text-muted-color');
    const surfaceBorder = documentStyle.getPropertyValue('--p-content-border-color');

    const labels = this.data().map(d => d.name);
    const currentMonth = this.data().map(d => d.currentMonthCount);
    const previousMonth = this.data().map(d => d.previousMonthCount);

    this.chartOpts = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          displayColors: false,
          enabled: true,
          callbacks: {
            label: (tooltipItem: any) => {
              const current = this.data()[tooltipItem.dataIndex].currentMonthCount;
              const previous = this.data()[tooltipItem.dataIndex].previousMonthCount;
              let change = ((current - previous) / (previous || 1)) * 100;

              // Cap the change at 100% or -100%
              if (change > 100) {
                change = 100;
              } else if (change < -100) {
                change = -100;
              }

              return [
                `Previous Month: ${previous}`,
                `Current Month: ${current}`,
                `Change: ${change.toFixed(1).replace('.0', '')}%`
              ];
            }
          }
        },
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          stacked: false,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            drawBorder: false
          }
        },
        y: {
          stacked: false,
          beginAtZero: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            drawBorder: false
          }
        }
      }
    };
    this.chartData = {
      labels: labels,
      datasets: [
        {
          label: 'Previous Month',
          backgroundColor: Colours_Palette.aquaBreeze,
          data: previousMonth,
        },
        {
          label: 'Current Month',
          backgroundColor: Colours_Palette.deepOceanBlue,
          data: currentMonth,
        }
      ]
    };
  }
}
