import {Component, effect, input} from '@angular/core';
import mapboxgl from 'mapbox-gl';
import {MapDto} from "../../../../shared/dtos/map.dto";
import {AppConstants} from "../../../../shared/utils/global";
import {MapService} from "../../../../shared/services/map.service";
import { MapPopupTypeEnum } from '../../../../shared/enums/dashboard/dashboard-widgets.enum';

@Component({
  selector: 'calimatic-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent {

  //inputs
  data = input.required<MapDto[]>();
  askLocation = input(false);
  popupType = input.required<MapPopupTypeEnum>();
  companyId = input.required<number>();
  
  //local
  mapInstance: mapboxgl.Map;

  constructor(private mapService: MapService) {
    effect(() => {
      this.drawMap();
    });
  }

  async drawMap() {
    if (this.data()?.length) {
      const companyLoc = this.data().find(x => x.id == this.companyId());
      this.mapInstance = await this.mapService.init(this.askLocation(), {
        lat: companyLoc?.lat,
        lng: companyLoc?.lng
      });
      this.setMarkers(this.data());
    } else {
      this.mapInstance = await this.mapService.init(this.askLocation());
    }
  }

  private setMarkers(data: MapDto[]) {
    const _this = this;
    // if(this.popupType() == 1){
    //   _this.mapInstance.addLayer({
    //     "id": "circle500",
    //     "type": "circle",
    //     "source": "source_circle_500",
    //     "paint": {
    //       "circle-radius": {
    //         stops: [
    //           [0, 0],
    //           [15, 8047] // 8047 = 5 mile
    //         ],
    //         base: 2
    //       },
    //       "circle-color": "blue",
    //       "circle-opacity": 0.1
    //     }
    //   });
    // }


    data.forEach((location) => {
      const el = document.createElement("map");
      el.className = 'user-marker';
      el.innerHTML = `<span><img src="${AppConstants.map.marker}" alt="marker"></span>`;
      el.style.borderColor = AppConstants.map.borderColor;
      let popup = new mapboxgl.Popup({offset: [-10, -10], closeButton: false}).setHTML(this.getPopup(location));

      new mapboxgl.Marker(el)
        .setLngLat([location.lng, location.lat])
        .setPopup(popup)
        .addTo(_this.mapInstance)
    });
  }
  private getPopup(item: MapDto){
    let companyInfo =
        `<h5 class="ellipsis">${item.name}</h5>
          <div class="map-popup-icon-info">
            <i class="fa-solid fa-location-dot mr-2"></i>
            <span class="ellipsis">${item.address?.trim()}</span>
          </div>
          <div class="map-popup-icon-info">
            <i class="fa-solid fa-envelope mr-2"></i>
            <span class="ellipsis">${item.email?.trim()}</span>
          </div>
          <div class="map-popup-icon-info">
            <i class="fa-solid fa-phone mr-2"></i>
            <span class="ellipsis">${item.phoneNumber?.trim()}</span>
          </div>`;

    let staffInfo=
        `<div class="map-popup-row-section">
            <ul>
              <li><b>No of Admins:</b><span>${item.admins}</span></li>
              <li><b>No of Executives:</b><span>${item.executives}</span></li>
              <li><b>No of Coordinators:</b><span>${item.coordinators}</span></li>
            </ul>
          </div>`;

    let franchiseInfo=
        `<div class="map-popup-card-section">
            <div class="map-popup_card card-1">
              <span>${item.instructors}</span>
              <h6>Instructors</h6>
            </div>
            <div class="map-popup_card card-2">
              <span>${item.contacts}</span>
              <h6>Contacts</h6>
            </div>
            <div class="map-popup_card card-3">
              <span>${item.students}</span>
              <h6>Students</h6>
            </div>
          </div>
        `
        return `<div class="map-popup-inner-content #infoPopup">
                  ${companyInfo}
                  ${this.popupType() == MapPopupTypeEnum.Dashboard ? staffInfo + franchiseInfo : ''}
                </div>
                `;
  }
}
