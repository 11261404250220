import {Component, effect, Inject, inject, OnInit, viewChild} from '@angular/core';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {TooltipModule} from 'primeng/tooltip';
import {SidebarModule} from 'primeng/sidebar';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {AccordionModule} from 'primeng/accordion';
import {CardModule} from 'primeng/card';
import {QuickLinksComponent} from './quick-links/quick-links.component';
import {BarChartComponent} from './charts/bar-chart/bar-chart.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {MapComponent} from './map/map.component';
import {CalendarModule} from 'primeng/calendar';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';
import {GridStackOptions} from 'gridstack';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DialogModule} from 'primeng/dialog';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {CommonModule} from '@angular/common';
import {getUserRoles, UserRoleEnum} from "../../../shared/enums/user-role.enum";
import {DropdownComponent} from "../../../shared/components/dropdown/dropdown.component";
import {DashboardService} from "../../../core/services/dashboard.service";
import {DashboardWidgetsDTO, DashboardWidgetsSettingsDTO} from "../../../shared/dtos/dashboard-widgets.dto";
import {AuthService} from "../../../core/services/auth/auth.service";
import {DashboardWidgetsEnum} from "../../../shared/enums/dashboard/dashboard-widgets.enum";
import {GridstackComponent, GridstackItemComponent, GridstackModule, nodesCB} from "gridstack/dist/angular";
import {SkeletonModule} from "primeng/skeleton";
import {cloneDeep, sortBy} from "lodash";
import {MemoizedPipe} from "../../../shared/pipes/memoized.pipe";
import {BreadcrumbComponent} from "../../../shared/components/breadcrumb/breadcrumb.component";
import {CounterWidgetComponent} from "./widgets/counter-widget/counter-widget.component";
import {UserGuideComponent} from "./user-guide/user-guide.component";
import {CompanyService} from "../../../core/services/company.service";
import {DropdownDto} from "../../../shared/dtos/dropdown-dto";
import {MultiSelectComponent} from "../../../shared/components/multi-select/multi-select.component";
import {SelectButtonComponent} from "../../../shared/components/select-button/select-button.component";
import {EnrollmentPerformanceComponent} from "./widgets/enrollment-performance/enrollment-performance.component";
import {ChartSkeletonComponent} from "./widgets/skeletons/chart-skeleton/chart-skeleton.component";
import {CounterSkeletonComponent} from "./widgets/skeletons/counter-skeleton/counter-skeleton.component";
import {CounterTrioWidgetComponent} from "./widgets/counter-trio-widget/counter-trio-widget.component";
import {PartnerEnrollmentComponent} from "./widgets/partner-enrollment/partner-enrollment.component";
import {TypeService} from "../../../core/services/type.service";
import {ClassDropOffWidgetComponent} from "./widgets/class-drop-off-widget/class-drop-off-widget.component";
import {CourseTypeWidgetComponent} from './widgets/course-type-widget/course-type-widget.component';
import {LeadsWidgetComponent} from "./widgets/leads-widget/leads-widget.component";
import {
  TopFranchisesRevenueWidgetComponent
} from "./widgets/top-franchises-revenue-widget/top-franchises-revenue-widget.component";
import {DashboardWidgetSectionEnum} from "../../../shared/enums/dashboard/dashboard-widget-section.enum";
import {EventDropOffWidgetComponent} from "./widgets/event-drop-off-widget/event-drop-off-widget.component";
import {EventTypeService} from "../../../core/services/event-type.service";
import {
  ClassEnrollmentCountWidgetComponent
} from "./widgets/class-enrollment-count-widget/class-enrollment-count-widget.component";
import {
  EventEnrollmentCountWidgetComponent
} from "./widgets/event-enrollment-count-widget/event-enrollment-count-widget.component";
import {SelectButtonModule} from 'primeng/selectbutton';
import {
  DashboardCalendarWidgetComponent
} from "./widgets/dashboard-calendar-widget/dashboard-calendar-widget.component";
import {UserInterface} from "../../../shared/interfaces/user.interface";
import {LeadsGenerationWidgetComponent} from "./widgets/leads-generation-widget/leads-generation-widget.component";
import {
  StudentDemographicsWidgetComponent
} from "./widgets/student-demographics-widget/student-demographics-widget.component";
import {StudentAttendanceComponent} from "./widgets/student-attendance-widget/student-attendance.component";
import {ExternalWidgetComponent} from "./widgets/external-widget/external-widget.component";
import {ClassRevenueWidgetComponent} from "./widgets/class-revenue-widget/class-revenue-widget.component";
import {AnnouncementsWidgetComponent} from "./widgets/announcements-widget/announcements-widget.component";
import {QuickLinksWidgetComponent} from "./widgets/quick-links-widget/quick-links-widget.component";
import {MapWidgetComponent} from "./widgets/map-widget/map-widget.component";
import {AppConstants, AppTokens, AppVideos} from "../../../shared/utils/global";
import {ExternalLinkDto} from "../../../shared/dtos/external-link.dto";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {IconsComponent} from "../../../shared/components/icons/icons.component";
import {DividerModule} from "primeng/divider";
import {TerminologiesDto} from "../../../shared/dtos/on-board.dto";
import {terminologyStore} from "../../../core/stores/terminology.store";
import {menuStore} from "../../../core/stores/menu.store";
import {TerminologyEnum, TerminologyWordEnum} from "../../../shared/enums/terminology.enum";
import {TerminologyPipe} from "../../../shared/pipes/terminology.pipe";
import {IConfirmationDialog} from "../../../shared/interfaces/confirm-dialog/IConfirmDialog.interface";
import {StudentPointsWidgetComponent} from './widgets/student-points-widget/student-points-widget.component';
import {HelpVideoComponent} from "../../../shared/components/help-video/help-video.component";
import {EnrollmentComparisonComponent} from "./widgets/enrollment-comparison/enrollment-comparison.component";

@Component({
  selector: 'calimatic-dashboard',
  standalone: true,
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  imports: [
    BreadcrumbModule,
    CommonModule,
    TooltipModule,
    SidebarModule,
    ButtonModule,
    DropdownModule,
    FormsModule,
    AccordionModule,
    CardModule,
    QuickLinksComponent,
    BarChartComponent,
    MultiSelectModule,
    MapComponent,
    CalendarModule,
    OverlayPanelModule,
    InputTextModule,
    InputSwitchModule,
    DialogModule,
    OrganizationChartModule,
    DropdownComponent,
    GridstackModule,
    SkeletonModule,
    MemoizedPipe,
    BreadcrumbComponent,
    CounterWidgetComponent,
    UserGuideComponent,
    MultiSelectComponent,
    SelectButtonComponent,
    EnrollmentPerformanceComponent,
    ChartSkeletonComponent,
    CounterSkeletonComponent,
    CounterTrioWidgetComponent,
    PartnerEnrollmentComponent,
    ClassDropOffWidgetComponent,
    CourseTypeWidgetComponent,
    LeadsWidgetComponent,
    TopFranchisesRevenueWidgetComponent,
    EventDropOffWidgetComponent,
    SelectButtonModule,
    ClassEnrollmentCountWidgetComponent,
    EventEnrollmentCountWidgetComponent,
    DashboardCalendarWidgetComponent,
    LeadsGenerationWidgetComponent,
    StudentDemographicsWidgetComponent,
    StudentAttendanceComponent,
    ReactiveFormsModule,
    ExternalWidgetComponent,
    ClassRevenueWidgetComponent,
    AnnouncementsWidgetComponent,
    QuickLinksWidgetComponent,
    MapWidgetComponent,
    InputGroupModule,
    InputGroupAddonModule,
    IconsComponent,
    DividerModule,
    TerminologyPipe,
    StudentPointsWidgetComponent,
    HelpVideoComponent,
    EnrollmentComparisonComponent
  ],
  providers: [TerminologyPipe]
})
export class DashboardComponent implements OnInit {
  menuStore = inject(menuStore);
  userRoles = getUserRoles();
  userRolesEnum = UserRoleEnum;
  selectedWidgets: DashboardWidgetsDTO[];
  nonSelectedWidgets: DashboardWidgetsDTO[];
  editDashboard = false;
  dashboardWidgetRole = UserRoleEnum.BusinessAdmin;
  widgetEnums = DashboardWidgetsEnum;
  gridStackCellHeight = AppConstants.defaultCellHeight;
  gridStackOpts: GridStackOptions = {
    cellHeight: this.gridStackCellHeight + 'px',
    disableDrag: true,
    disableResize: true,
    animate: true,
    columnOpts: {
      breakpointForWindow: true,
      breakpoints: [{w: 1100, c: 1}]
    },
  };
  showLoader = true;
  widgetSearch = '';
  loggedInUserRole: UserRoleEnum;
  helpDialogVisible = false;
  franchises: DropdownDto[] = [];
  types: DropdownDto[] = [];
  eventTypes: DropdownDto[] = [];
  sectionWidgetEnum = DashboardWidgetSectionEnum;
  userModal: UserInterface = {} as UserInterface;
  externalLinkModal = false;
  externalLinkForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]),
    link: new FormControl('', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]),
    type: new FormControl(),
    icon: new FormControl()
  });
  showExternalLinkLoader = false;
  restoreLoader = false;
  dashboardVideo = AppVideos.dashboard;
  terminologies: TerminologiesDto[] = [];
  widgetModalVisibility = false;
  protected readonly terminologyEnum = TerminologyEnum;
  protected readonly terminologyWordType = TerminologyWordEnum;
  private widgets: DashboardWidgetsDTO[];
  private gsElmRef = viewChild.required<GridstackComponent>('gs');
  #terminologiesStore = inject(terminologyStore);

  constructor(@Inject(AppTokens.confirmation) private confirmDialogService: IConfirmationDialog, private terminologyPipe: TerminologyPipe, private dashboardService: DashboardService, private typeService: TypeService, private authService: AuthService, private companyService: CompanyService, private eventTypeService: EventTypeService) {
    effect(async () => {
      this.terminologies = this.#terminologiesStore.terminologies();
      if (this.terminologies?.length && this.widgets?.length) {
        this.widgets = this.dashboardService.applyWidgetsTerminologies(this.widgets, this.terminologies);
        this.setDashboardWidgets();
      }
    });
  }

  get externalLinkFormControls() {
    return this.externalLinkForm.controls;
  }

  private get widgetList() {
    return this.widgets || []
  }

  private get gsRef() {
    return this.gsElmRef()?.grid;
  }

  async ngOnInit() {
    this.userModal = this.authService.getUserModal();
    this.loggedInUserRole = Number(this.userModal.Role);
    this.dashboardWidgetRole = this.loggedInUserRole;
    this.widgets = (await this.dashboardService.getWidgets(this.userModal.CompanyId, this.userModal.IsFranchiseCompany, this.userModal.PaymentGatewaySymbol));
    this.widgets = this.dashboardService.applyWidgetsTerminologies(this.widgets, this.terminologies);
    this.setDashboardWidgets();
    this.showLoader = false;

    if (!this.userModal.IsFranchiseCompany) {
      this.franchises = await this.companyService.getFranchises();
    }
    this.types = await this.typeService.getTypes();
    this.eventTypes = await this.eventTypeService.getTypes();
  }

  async addWidget(w: DashboardWidgetsDTO) {
    this.dashboardService.addWidget(w);
    this.setDashboardWidgets();
  }

  async removeWidget(w: DashboardWidgetsDTO) {
    this.confirmDialogService.confirm('Are you sure?', 'Do you want to remove this widget from the dashboard?', () => {
      this.dashboardService.removeWidget(w);
      this.setDashboardWidgets();
    }, () => {
    }, 'Remove', 'p-button-primary');
  }

  async updateWidgets(data: nodesCB) {
    if (this.editDashboard && data.nodes?.length) {
      let payload = data.nodes.map(x => {
        return {
          width: x.w || 0,
          height: x.h || 0,
          xaxis: x.x || 0,
          yaxis: x.y || 0,
          id: x.id,
          isEnabled: true
        } as DashboardWidgetsSettingsDTO
      });
      cloneDeep(this.widgetList)?.forEach(x => {
        let wd = payload.find(n => n.id == x.id);
        if (wd) {
          x.width = wd.width;
          x.height = wd.height;
          x.xaxis = wd.xaxis;
          x.yaxis = wd.yaxis;
        }
      });

      if (data.event.type == "added") {
        this.moveToBottom(data.nodes[0]?.id || '');
      }

      await this.dashboardService.updateWidgets(payload);
    }
  }

  onEditDashboard() {
    this.editDashboard = !this.editDashboard;
    this.enableDisableWidgetsMovement();

    //on edit close, load log-in user role dashboard
    if (!this.editDashboard) {
      if (this.loggedInUserRole != this.dashboardWidgetRole) {
        this.dashboardWidgetRole = this.loggedInUserRole;
        this.onRoleChange();
      }
    }
  }

  filterByCategory(widgetList: DashboardWidgetsDTO[], section: DashboardWidgetSectionEnum) {
    return (widgetList || []).filter(x => x.category == section);
  }

  onRoleChange() {
    this.showLoader = true;
    this.widgetSearch = '';
    this.setDashboardWidgets();
    this.enableDisableWidgetsMovement();
    setTimeout(() => {
      this.showLoader = false;
    }, 200);
  }

  setNonSelectedWidgets() {
    const srhKey = this.widgetSearch?.trim()?.toLowerCase();
    this.nonSelectedWidgets = this.widgetList.filter(x => (!srhKey?.length || x.title?.trim()?.toLowerCase().includes(srhKey)) && !x.isEnabled && x.role == this.dashboardWidgetRole);
  }

  showExternalLinkModal(data: ExternalLinkDto) {
    this.externalLinkModal = true;
    this.externalLinkForm.patchValue(data);
  }

  selectedExternalLinkIcon(icon: string) {
    this.externalLinkFormControls.icon.patchValue(icon);
  }

  async onExternalLinkFormSubmit() {
    if (this.externalLinkForm.invalid)
      return;

    let elm = this.widgetList.find(x => x.code == this.externalLinkForm.value.type);
    if (elm?.links?.length && !this.userModal.IsFranchiseCompany) {
      this.confirmDialogService.confirm(`Sync with ${this.terminologyPipe.transform('franchise', this.terminologyEnum.Franchise)}?`, `Do you want to sync this with ${this.terminologyPipe.transform('franchises', this.terminologyEnum.Franchise, TerminologyWordEnum.PLURAL)}?`, async () => {
        await this.saveExternalLink(true);
      }, async () => {
        await this.saveExternalLink(false);
      }, 'Yes', 'p-button-primary', 'No');
    } else {
      await this.saveExternalLink(false);
    }
  }

  async saveExternalLink(sync: boolean) {
    this.showExternalLinkLoader = true;
    const payload = this.externalLinkForm.value as ExternalLinkDto;
    payload.sync = sync;
    const res = await this.dashboardService.saveExternalLink(payload);
    this.showExternalLinkLoader = false;
    if (res.isSuccessful) {
      this.externalLinkModal = false;
      this.externalLinkForm.reset();
      let elms = this.widgetList.filter(x => x.code == payload.type);
      if (elms?.length) {
        elms.forEach(e => {
          e.title = payload.name;
          e.link = payload.link;
          e.icon = payload.icon;
        });
      }
    }
  }

  onAnnouncementToggle(height: number, gsi: GridstackItemComponent) {
    if (this.gsRef && gsi) {
      this.gsRef.update(gsi.el, {h: height});
    }
  }

  async restoreToDefault() {
    this.showLoader = true;
    this.restoreLoader = true;
    await this.dashboardService.restoreToDefault(this.dashboardWidgetRole);

    this.widgets = (await this.dashboardService.getWidgets(this.userModal.CompanyId, this.userModal.IsFranchiseCompany, this.userModal.PaymentGatewaySymbol));
    this.widgets = this.dashboardService.applyWidgetsTerminologies(this.widgets, this.terminologies);
    this.setDashboardWidgets();
    this.showLoader = false;
    this.restoreLoader = false;
  }

  private setDashboardWidgets() {
    this.setSelectedWidgets();
    this.setNonSelectedWidgets();
  }

  private setSelectedWidgets() {
    this.selectedWidgets = sortBy(this.widgetList.filter(x => x.isEnabled && x.role == this.dashboardWidgetRole), [w => w.yaxis, x => x.xaxis]);
  }

  private enableDisableWidgetsMovement() {
    if (this.gsRef) {

      //this will handle if the GS grid is already loaded and is in DOM
      this.gsRef.enableMove(this.editDashboard);
      this.gsRef.enableResize(this.editDashboard);

      //this will handle if GS grid is re init, normally when we change role and re load content
      this.gridStackOpts.disableResize = !this.editDashboard;
      this.gridStackOpts.disableDrag = !this.editDashboard;
    }
  }

  private moveToBottom(id: string) {
    setTimeout(() => {
      const elm = document.querySelector(`[gs-id="${id}"]`);
      if (elm) {
        elm.scrollIntoView({behavior: "auto"});
      }
    }, 100);
  }
}
