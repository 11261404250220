import {Component, effect, input, output} from '@angular/core';
import {ChartSkeletonComponent} from "../skeletons/chart-skeleton/chart-skeleton.component";
import {DropdownComponent} from "../../../../../shared/components/dropdown/dropdown.component";
import {MultiSelectComponent} from "../../../../../shared/components/multi-select/multi-select.component";
import {SelectButtonComponent} from "../../../../../shared/components/select-button/select-button.component";
import {StackBarChartComponent} from "../../charts/stack-bar-chart/stack-bar-chart.component";
import {TerminologyPipe} from "../../../../../shared/pipes/terminology.pipe";
import {DropdownDto} from "../../../../../shared/dtos/dropdown-dto";
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {DashboardEnrollmentTypes} from "../../../../../shared/enums/dashboard/dashboard-enrollment-types";
import {MultiSelectDisplayEnums} from "../../../../../shared/enums/multi-select/multi-select.enums";
import {VerticalBarChartDto} from "../../../../../shared/dtos/chart-data.dto";
import {TerminologyEnum, TerminologyWordEnum} from "../../../../../shared/enums/terminology.enum";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {TooltipModule} from "primeng/tooltip";
import {VerticalBarChartComponent} from "../../charts/vertical-bar-chart/vertical-bar-chart.component";

@Component({
  selector: 'calimatic-enrollment-comparison',
  standalone: true,
  imports: [
    ChartSkeletonComponent,
    DropdownComponent,
    MultiSelectComponent,
    SelectButtonComponent,
    StackBarChartComponent,
    TerminologyPipe,
    TooltipModule,
    VerticalBarChartComponent
  ],
  templateUrl: './enrollment-comparison.component.html',
  styleUrl: './enrollment-comparison.component.scss'
})
export class EnrollmentComparisonComponent {

  //inputs
  types = input.required<DropdownDto[]>();
  eventTypes = input.required<DropdownDto[]>();
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required();

  //outputs
  onRemove = output();

  //local
  selectedType: string[];
  enrollmentTypes = DashboardEnrollmentTypes;
  multiSelectDisplay = MultiSelectDisplayEnums;
  selectedEnrollmentType: number;
  chartData: VerticalBarChartDto[];
  loading = false;
  protected readonly terminologyEnum = TerminologyEnum;
  protected readonly terminologyWordType = TerminologyWordEnum;

  constructor(private dashboardService: DashboardService) {
    effect(async () => {
      this.selectedEnrollmentType = this.enrollmentTypes[0].id;
      this.setType();
      await this.loadData();
    });
  }

  async onTypeChange(value: any) {
    this.selectedType = value;
    await this.loadData();
  }

  async onEnrollmentTypeChange(value: any) {
    this.selectedEnrollmentType = value;
    this.setType();
    await this.loadData();
  }

  async loadData() {
    if (this.selectedType?.length) {
      this.loading = true;
      this.chartData = await this.dashboardService.getEnrollmentComparison(this.selectedType.toString(), this.selectedEnrollmentType);
      this.loading = false;
    }
  }

  getType() {
    return this.selectedEnrollmentType == 1 ? this.types() : this.eventTypes();
  }

  private setType() {
    this.selectedType = this.selectedEnrollmentType == 1 ? this.types().map(x => x.name) : this.eventTypes().map(x => x.name);
  }
}
