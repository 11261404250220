import {Injectable} from "@angular/core";
import {AppConstants} from "../utils/global";
import {GeoLocationPositionDTO} from "../dtos/geo-location-position-dto";
import * as mapboxgl from "mapbox-gl";
import {environment} from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class MapService {
  
  constructor(private http: HttpClient) {}


  async init(askLocation: boolean, defaultLocation?: { lat?: number, lng?: number }) {
    let location = {
      lat: defaultLocation?.lat || AppConstants.map.defaultLocation.lat,
      lng: defaultLocation?.lng || AppConstants.map.defaultLocation.lng,
    };

    if (askLocation) {
      if ('geolocation' in navigator) {
        try {
          let currentLoc = (await this.getUserLocation()) as GeoLocationPositionDTO;
          location.lng = currentLoc.coords.longitude;
          location.lat = currentLoc.coords.latitude;
        } catch (error) {
        }
      }
    }


    let instance = new mapboxgl.Map({
      accessToken: environment.mapbox.accessToken,
      container: 'map',
      style: AppConstants.map.streetView,
      center: [location.lng, location.lat],
      zoom: AppConstants.map.zoomLevel,
      maxZoom: 60,
      minPitch: 0,
      maxPitch: 60
    });
    instance.addControl(new mapboxgl.NavigationControl());
    instance.addControl(new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    }));
    instance.on('load', () => {
      if (instance.getLayer("circle500")) {
        instance.removeLayer("circle500");
      }
      if (instance.getSource("source_circle_500")) {
        instance.removeSource("source_circle_500");
      }

      let ft: any = [{
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [location.lng, location.lat]
        }
      }];
      instance.addSource("source_circle_500", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: ft
        }
      });
      instance.addLayer({
        "id": "circle500",
        "type": "circle",
        "source": "source_circle_500",
        "paint": {
          "circle-radius": {
            stops: [
              [0, 0],
              [15, 5024]
            ],
            base: 2
          },
          "circle-color": "blue",
          "circle-opacity": 0.1
        }
      });
      new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true
      });
    });
    
    return instance;
  }

  private getUserLocation(opt?: any) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, opt);
    });
  }

  getLocations(search: string) {
    return this.http.get<any>(environment.mapbaseUrl + 'geocoding/v5/mapbox.places/' + search + '.json?types=place&access_token=' + environment.mapbox.accessToken);
  }

  getLocationNames(lat: number, long: number) {
    return this.http.get<any>(environment.mapbaseUrl + 'geocoding/v5/mapbox.places/' + lat + ',' + long + '.json?types=place,region,country&access_token=' + environment.mapbox.accessToken);
  }
}
