import {Component, input, OnInit, output} from '@angular/core';
import {MapComponent} from "../../map/map.component";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {MapDto} from "../../../../../shared/dtos/map.dto";
import {ChartSkeletonComponent} from "../skeletons/chart-skeleton/chart-skeleton.component";
import { TooltipModule } from 'primeng/tooltip';
import { MapPopupTypeEnum } from '../../../../../shared/enums/dashboard/dashboard-widgets.enum';

@Component({
  selector: 'calimatic-map-widget',
  standalone: true,
  imports: [
    MapComponent,
    ChartSkeletonComponent,
    TooltipModule
  ],
  templateUrl: './map-widget.component.html',
  styleUrl: './map-widget.component.scss'
})
export class MapWidgetComponent implements OnInit {

  //inputs
  edit = input.required<boolean>();
  companyId = input.required<number>();

  //outputs
  onRemove = output();

  //local
  data: MapDto[] = [];
  loading = false;
  mapPopupTypeEnum = MapPopupTypeEnum;

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    this.loading = true;
    this.data = await this.dashboardService.getMapData();
    this.loading = false;
  }
}
